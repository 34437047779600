import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { db, functions } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import { httpsCallable } from "firebase/functions";
import { SubscriptionType } from "./SubscriptionModule";

export enum AccountUpgradeStatus {
  registered = "registered",
  cancelled = "cancelled",
  rejected = "rejected",
  onVerify = "onVerify",
  onProgress = "onProgress",
  noRequest = "noRequest",
}

export enum AccountPageQuery {
  all = "all",
  admin = "admin",
  superteam = "superteam",
  appUser = "appUser",
  subscription = "subscription",
}

export enum ProfileRole {
  consumer = "Konsumen",
  farmer = "Petani",
  empty = "-",
}

export enum UserStatus {
  active = "active",
  suspended = "suspended",
}

export enum UserAuthorization {
  root = "root",
  admin = "admin",
  appUser = "appUser",
  superteam = "superteam",
}

interface AccountStoreState {
  doc: Record<string, AccountDoc>;
  index: { [key: string]: AccountIndex };
  indexUnsub: Unsubscribe | null;
  pageQuery: { [key in AccountPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
  modal: { userEdit: { uid: string | null } };
}

export interface AccountIndex {
  uid: string;
  create: Timestamp | null;

  email: string;
  name: string | null;
  photo: string;
  role: string | null;

  subs: string | null;
  status: string | null;
  expiry: Timestamp | null;
}

interface AccountDoc {
  user: UserDoc | null;
  profile: ProfileDoc | null;
  superteam?: SuperteamDoc | null;
}

interface UserDoc {
  id: string;
  data: UserDocData;
  unsub: Unsubscribe | null;
}

interface ProfileDoc {
  id: string;
  data: ProfileDocData;
  unsub: Unsubscribe | null;
}

interface SuperteamDoc {
  id: string;
  data: SuperteamDocData;
  unsub: Unsubscribe | null;
}

interface UserDocData {
  uid: string;
  email: string;
  registerDate: Timestamp | null;
  lastOnlineDate: Timestamp | null;
  authorization: UserAuthorization;
  purchase: {
    total: number;
    lastDate: Timestamp | null;
  };
  sale: {
    total: number;
    lastDate: Timestamp | null;
  };
  subscription: {
    type: SubscriptionType;
    endDate: Timestamp | null;
  };
  status: UserStatus;
  location: {
    geopoint: GeoPoint | null;
    address: string;
  };
}

interface ProfileDocData {
  uid: string;
  photoURL: string;
  name: string;
  role: ProfileRole;
  phone: string;
  address: string;

  upgrade: {
    lastUpdated: Timestamp | null;
    receiptImageURL: string;
    price: number;
    type: SubscriptionType;
    status: AccountUpgradeStatus;
    duration: {
      period: number;
      unit: string;
    };
  };
}

interface SuperteamDocData {
  uid: string;
  name: string;
  contact: {
    whatsapp: string;
  };
  location: {
    geopoint: GeoPoint | null;
    address: string;
  };
}

@Module
export default class AccountModule
  extends VuexModule
  implements AccountStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: AccountDoc };
  index = {} as { [key: string]: AccountIndex };
  indexUnsub = null as Unsubscribe | null;
  pageQuery = {
    all: [],
    admin: [],
    superteam: [],
    appUser: [],
    subscription: [],
  } as { [key in AccountPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };
  modal = { userEdit: { uid: null } } as AccountStoreState["modal"];

  @Mutation
  [Mutations.ACCOUNT_SET_MODAL_USEREDIT](uid: string) {
    this.modal.userEdit.uid = uid ?? null;
  }

  @Mutation
  [Mutations.ACCOUNT_SET_INDEX](payload: { id: string; data: DocumentData }) {
    const id = payload.id;
    const data = payload.data;

    const docData = {
      uid: id,
      create: data.create ?? null,

      email: data.email ?? "",
      photo: data.photo ?? "",
      name: data.name ?? null,
      role: data.role ?? null,

      subs: data.subs ?? null,
      status: data.status ?? null,
      expiry: data.expiry ?? null,
    } as AccountIndex;

    if (id) this.index[id] = docData;
    // console.log(docData)
  }

  @Mutation
  [Mutations.ACCOUNT_SET_INDEX_SUB](payload: { unsub: Unsubscribe }) {
    const unsub = payload.unsub;
    this.indexUnsub = unsub ?? null;
  }

  @Mutation
  [Mutations.ACCOUNT_SET_USER_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      email: data.email ?? "",
      authorization: data.authorization ?? UserAuthorization.appUser,
      registerDate: data.registerDate ?? null,
      lastOnlineDate: data.lastOnlineDate ?? null,
      purchase: {
        total: data.purchase?.total ?? null,
        lastDate: data.purchase?.lastDate ?? null,
      },
      sale: {
        total: data.sale?.total ?? 0,
        lastDate: data.sale?.lastDate ?? null,
      },
      subscription: {
        type: data.subscription?.type ?? SubscriptionType.free,
        endDate: data.subscription?.endDate ?? null,
      },
      status: data.status ?? UserStatus.active,
      location: {
        geopoint: data.location?.geopoint ?? null,
        address: data.location?.address ?? "",
      },
    } as UserDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].user = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.user) {
      this.doc[id].user!.data = docData;
    } else {
      console.error(`FAILED SETTING USER DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_PROFILE_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      photoURL:
        data.photoURL?.length ?? 0 > 0
          ? data.photoURL
          : data.imageURL?.length ?? 0 > 0
          ? data.imageURL
          : "",
      name: data.name ?? "",
      role: data.role ?? ProfileRole.empty,
      phone: data.phone ?? "",
      address: data.address ?? "",

      upgrade: {
        lastUpdated: data.upgrade?.lastUpdated ?? null,
        receiptImageURL: data.upgrade?.receiptImageURL ?? "",
        price: data.upgrade?.price ?? 0,
        type:
          data.upgrade?.type in SubscriptionType
            ? data.upgrade?.type
            : SubscriptionType.free,
        status:
          data.upgrade?.status in AccountUpgradeStatus
            ? data.upgrade?.status
            : AccountUpgradeStatus.noRequest,
        duration: {
          period: 6,
          unit: "month",
        },
      },
    } as ProfileDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].profile = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.profile) {
      this.doc[id].profile!.data = docData;
    } else {
      console.error(`FAILED SETTING PROFILE DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_SUPERTEAM_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: id,
      name: data.name ?? "",
      contact: {
        whatsapp: data.contact?.whatsapp ?? "",
      },
      location: {
        geopoint: data.location?.geopoint ?? null,
        address: data.location?.address ?? "",
      },
    } as SuperteamDocData;

    if (unsub) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
      this.doc[id].superteam = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]?.superteam) {
      this.doc[id].superteam!.data = docData;
    } else {
      console.error(`FAILED SETTING ACCOUNT SUPERTEAM DOC`);
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_PAGE_QUERY](payload: {
    key: AccountPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    for (const id in ids) {
      if (!this.doc[id])
        this.doc[id] = {
          user: null,
          profile: null,
          superteam: null,
        };
    }

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.ACCOUNT_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  async [Actions.ACCOUNT_POPULATE_PAGE_QUERY](payload: {
    key: AccountPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    if (key === AccountPageQuery.appUser) {
      if (!this.indexUnsub) {
        const userIndexQuery = query(
          collection(db, "queryIndices"),
          where("target", "==", "account")
        );
        this.context.commit(Mutations.ACCOUNT_SET_INDEX_SUB, {
          unsub: onSnapshot(userIndexQuery, (userIndexDocs) => {
            userIndexDocs.docs.forEach((x) => {
              Object.entries(x.data().account).forEach((y) => {
                this.context.commit(Mutations.ACCOUNT_SET_INDEX, {
                  id: y[0],
                  data: y[1] as DocumentData,
                });
              });
            });
          }),
        });
      }
    } else {
      let userQuery: Query<DocumentData>;
      switch (key) {
        // case AccountPageQuery.appUser:
        //   userQuery = query(
        //     collection(db, "users"),
        //     where("versionNo", "==", 0),
        //     where("authorization", "==", UserAuthorization.appUser),
        //     orderBy("registerDate", "desc")
        //     // limit(50)
        //   );
        //   break;
        case AccountPageQuery.admin:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.admin),
            orderBy("registerDate", "desc")
            // limit(50)
          );
          break;
        case AccountPageQuery.superteam:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            where("authorization", "==", UserAuthorization.superteam),
            orderBy("registerDate", "desc")
            // limit(50)
          );
          break;
        case AccountPageQuery.subscription:
          userQuery = query(
            collection(db, "profiles"),
            where("versionNo", "==", 0),
            // where("upgrade", "!=", null),
            orderBy("upgrade.lastUpdated", "desc")
            // limit(50)
          );
          break;
        case AccountPageQuery.all:
        default:
          userQuery = query(
            collection(db, "users"),
            where("versionNo", "==", 0),
            orderBy("registerDate", "desc")
            // limit(50)
          );
          break;
      }

      getDocs(userQuery)
        .then((userDocs) => {
          const ids: Array<string> = [];

          userDocs.forEach((userDoc) => {
            ids.push(userDoc.id);
            if (!this.doc[userDoc.id]?.user) {
              const unsub = onSnapshot(
                doc(db, "users", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data() ?? {},
                    unsub: unsub,
                  });
                }
              );
            }
            if (!this.doc[userDoc.id]?.profile) {
              const unsub = onSnapshot(
                doc(db, "profiles", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data(),
                    unsub: unsub,
                  });
                }
              );
            }
            if (
              userDoc.data().authorization === UserAuthorization.superteam &&
              !this.doc[userDoc.id]?.superteam
            ) {
              const unsub = onSnapshot(
                doc(db, "superteams", userDoc.id),
                (dbDoc) => {
                  this.context.commit(Mutations.ACCOUNT_SET_SUPERTEAM_DOC, {
                    id: dbDoc.id,
                    data: dbDoc.data(),
                    unsub: unsub,
                  });
                }
              );
            }
          });

          this.context.commit(Mutations.ACCOUNT_SET_PAGE_QUERY, {
            key: key,
            ids: ids,
            append: append,
          });
        })
        .catch((error) => console.log("owo", error.message));
    }
  }

  @Action
  async [Actions.ACCOUNT_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "profiles"),
      where("versionNo", "==", 0),
      orderBy("name"),
      where("name", "==", key),
      where("name", "==", key + "~")
      // limit(50)
    );

    const userDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    userDocs.forEach((userDoc) => {
      ids.push(userDoc.id);
      if (!this.doc[userDoc.id]?.user) {
        const unsub = onSnapshot(doc(db, "users", userDoc.id), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (!this.doc[userDoc.id]?.profile) {
        const unsub = onSnapshot(doc(db, "profiles", userDoc.id), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (
        key === AccountPageQuery.superteam &&
        !this.doc[userDoc.id]?.superteam
      ) {
        const unsub = onSnapshot(doc(db, "superteams", userDoc.id), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_SUPERTEAM_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
    });

    this.context.commit(Mutations.ACCOUNT_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action({ rawError: true })
  [Actions.ACCOUNT_GET_SINGLE](payload: { uid: string; isSuperteam: boolean }) {
    console.log("ACCOUNT_GET_SINGLE", payload);
    const uid = payload.uid;
    const isSuperteam = payload.isSuperteam;
    if (!this.doc[uid]) {
      if (!this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "users", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_USER_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (!this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "profiles", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_PROFILE_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
      if (isSuperteam && !this.doc[uid]) {
        const unsub = onSnapshot(doc(db, "superteams", uid), (dbDoc) => {
          this.context.commit(Mutations.ACCOUNT_SET_SUPERTEAM_DOC, {
            id: dbDoc.id,
            data: dbDoc.data() ?? {},
            unsub: unsub,
          });
        });
      }
    }
  }

  @Action({ rawError: true })
  [Actions.ACCOUNT_UPDATE_DOC](payload: {
    id: string;
    user: { [x: string]: any } | undefined;
    profile: { [x: string]: any } | undefined;
    superteam: { [x: string]: any } | undefined;
  }) {
    const id = payload.id;
    const user = payload.user;
    const profile = payload.profile;
    const superteam = payload.superteam;

    // Get a new write batch
    const batch = writeBatch(db);

    if (user) {
      // Set profile value
      const userRef = doc(db, "users", id);
      batch.update(userRef, user);
    }

    if (profile) {
      // Set profile value
      const profileRef = doc(db, "profiles", id);
      batch.update(profileRef, profile);
    }

    if (superteam) {
      // Set profile value
      const superteamRef = doc(db, "superteams", id);
      batch.update(superteamRef, superteam);
    }

    // Commit the batch
    return batch.commit();
  }

  @Action({ rawError: true })
  async [Actions.ACCOUNT_CREATE](payload: {
    id: string;
    email: string;
    password: string;
    user: DocumentData | undefined;
    profile: DocumentData | undefined;
    superteam: DocumentData | undefined;
  }) {
    const id = payload.id;
    const email = payload.email;
    const password = payload.password;

    const user = payload.user;
    const profile = payload.profile;
    const superteam = payload.superteam;

    const createUser = httpsCallable(functions, "createUser");
    await createUser({
      uid: id,
      email: email,
      password: password,
      name: profile?.name ?? undefined,
      photoURL: profile?.photoURL ?? undefined,
    }).then((result) => {
      console.log("cf result:", "createUser", result.data);
    });

    // Get a new write batch
    const batch = writeBatch(db);

    if (user) {
      // Set profile value
      const userRef = doc(db, "users", id);
      batch.set(userRef, {
        versionNo: 0,
        ...user,
      });
    }

    if (profile) {
      // Set profile value
      const profileRef = doc(db, "profiles", id);
      batch.set(profileRef, {
        versionNo: 0,
        ...profile,
      });
    }

    if (superteam) {
      // Set profile value
      const superteamRef = doc(db, "superteams", id);
      batch.set(superteamRef, {
        versionNo: 0,
        ...superteam,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export {
  AccountStoreState,
  AccountDoc,
  UserDocData,
  ProfileDocData,
  SuperteamDocData,
};
