import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";

// // PRODUCION
const firebaseConfig = {
  apiKey: "AIzaSyCVhaEX85zh-z1GzXGCqLnUjRHU4z8jV9Y",
  authDomain: "markaswalet-xomodo.firebaseapp.com",
  projectId: "markaswalet-xomodo",
  storageBucket: "markaswalet-xomodo.appspot.com",
  messagingSenderId: "784736478235",
  appId: "1:784736478235:web:7b9ae2e215d2aa547f84be",
  measurementId: "G-3LESK0E49K",
};

// DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyBhETL38dLQumLbpGLJcE0F4pZEhMPNT8c",
//   authDomain: "markaswalet-dev.firebaseapp.com",
//   projectId: "markaswalet-dev",
//   storageBucket: "markaswalet-dev.appspot.com",
//   messagingSenderId: "150086034439",
//   appId: "1:150086034439:web:7a51ba3e00503ee46cb6a2",
//   measurementId: "G-BZB2D679E7"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app, "asia-southeast2");
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, analytics, functions, db, storage };
