import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { db } from "@/core/services/FirebaseService";
import {
  doc,
  writeBatch,
  query,
  collection,
  orderBy,
  where,
  onSnapshot,
  Timestamp,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum ManufacturePageStatus {
  all = "all",
  raw = "raw",
  washing = "washing",
  molding = "molding",
  clean = "clean",
}

interface ManufactureStoreState {
  doc: { [key: string]: ManufactureDoc };
  pageQuery: { [key in ManufacturePageStatus]: Array<string> };
  textQuery: { [key: string]: Array<string> };
  searchText: String;
}

interface ManufactureDoc {
  id: string;
  data: ManufactureDocData;
  unsub: Unsubscribe | null;
}

interface ManufactureDocData {
  versionNo: number;
  id: string; // id manufaktur / id panen
  status: string; // status manufaktur
  harvest: {
    id: string; // id panen
    dateHarvest: Timestamp; // tgl panen
    location: string; // lokasi panen
    weight: number; // berat panen
    qty: number;  // total keping panen
  };
  input: {
    date: Timestamp; // tgl masuk pemrosesan / tgl masuk gudang kotor
    item: {
      [key: string]: { // id barang
        note: string; // keterangan / catatan
        type: string; // tipe
        color: string; // warna
        qty: number; // total keping
        weight: number; // total berat
      };
    };
  };
  output: {
    date: Timestamp; // tgl keluar pemrosesan / tgl masuk gudang bersih
    item: {
      [key: string]: { // id barang
        note: string; // keterangan / catatan
        type: string; // tipe
        color: string; // warna
        qty: number; // total keping
        weight: number; // total berat
      };
    };
  } | null;
  washing: {
    dateStart: Timestamp  | null; // tgl mulai pencucian
    dateEnd: Timestamp  | null; // tgl selesai pencucian
    assignment: {
      [key: string]: {
        note: string;
        input: {
          date: Timestamp; // tgl masuk pencucian
          item: {
            [key: string]: { // id barang
              note: string; // keterangan / catatan
              qty: number; // total keping
              weight: number; // total berat
            };
          };
        };
        output: {
          date: Timestamp; // tgl keluar pencucian
          item: {
            [key: string]: { // id barang
              note: string; // keterangan / catatan
              qty: number; // total keping
              weight: number; // total berat
              flakes: number; // serpihan
              trash: number; // sampah
            };
          };
        } | null;
      };
    };
  } | null;
  molding: {
    dateStart: Timestamp  | null; // tgl mulai pencetakan
    dateEnd: Timestamp  | null; // tgl selesai pencetakan
    assignment: {
      [key: string]: {
        note: string;
        input: {
          date: Timestamp; // tgl masuk pencetakan
          item: {
            [key: string]: { // id barang
              note: string; // keterangan / catatan
              qty: number; // total keping
              weight: number; // total berat
            };
          };
        };
        output: {
          date: Timestamp; // tgl keluar pencetakan
          item: {
            [key: string]: { // id barang
              note: string; // keterangan / catatan
              qty: number; // total keping
              weight: number; // total berat
              flakes: number; // serpihan
              trash: number; // sampah
            };
          };
        } | null;
      };
    };
  } | null;
}

@Module
export default class ManufactureModule
  extends VuexModule
  implements ManufactureStoreState
{
  errorMessage = null;
  searchText = "";
  doc = {} as { [key: string]: ManufactureDoc };
  pageQuery = {} as { [key in ManufacturePageStatus]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.MANUFACTURE_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      id: id,
      ...data,
    } as ManufactureDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING MANUFACTURE DOC`);
    }
  }

  @Mutation
  [Mutations.MANUFACTURE_SET_PAGE_QUERY](payload: {
    key: ManufacturePageStatus;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Action
  [Actions.MANUFACTURE_POPULATE_PAGE_QUERY](payload: {
    key: ManufacturePageStatus;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      // case ManufacturePageStatus.raw:
      //   dbQuery = query(
      //     collection(db, "manufactures"),
      //     where("versionNo", "==", 1),
      //     where("status", "==", "raw"),
      //     orderBy("input.date", "desc")
      //   );
      //   break;
      // case ManufacturePageStatus.washing:
      //   dbQuery = query(
      //     collection(db, "manufactures"),
      //     where("versionNo", "==", 1),
      //     where("status", "==", "washing"),
      //     orderBy("input.date", "desc")
      //   );
      //   break;
      // case ManufacturePageStatus.molding:
      //   dbQuery = query(
      //     collection(db, "manufactures"),
      //     where("versionNo", "==", 1),
      //     where("status", "==", "molding"),
      //     orderBy("input.date", "desc")
      //   );
      //   break;
      // case ManufacturePageStatus.clean:
      //   dbQuery = query(
      //     collection(db, "manufactures"),
      //     where("versionNo", "==", 1),
      //     where("status", "==", "clean"),
      //     orderBy("input.date", "desc")
      //   );
      //   break;
      // case ManufacturePageStatus.all:
      default:
        dbQuery = query(
          collection(db, "manufactures"),
          where("versionNo", "==", 1),
          orderBy("input.date", "desc")
        );
        break;
    }

    getDocs(dbQuery)
      .then((dbDocs) => {
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(doc(db, "manufactures", dbDoc.id), (dbDoc) => {
              this.context.commit(Mutations.MANUFACTURE_SET_DOC, {
                id: dbDoc.id,
                data: dbDoc.data(),
              });
            });
            this.context.commit(Mutations.MANUFACTURE_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.MANUFACTURE_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("ERROR", "MANUFACTURE_POPULATE_PAGE_QUERY", error.message));
  }

  @Action({ rawError: true })
  [Actions.MANUFACTURE_UPDATE_DOC](payload: {
    id: string;
    manufacture: { [x: string]: any };
  }) {
    const id = payload.id;
    const manufacture = payload.manufacture;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const manufactureRef = doc(db, "manufactures", id);
    batch.update(manufactureRef, manufacture);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.MANUFACTURE_CREATE](payload: {
    id: string;
    manufacture: DocumentData;
  }) {
    const id = payload.id;
    const manufacture = payload.manufacture;

    // Get a new write batch
    const batch = writeBatch(db);

    if (manufacture) {
      // Set profile value
      const manufactureRef = doc(db, "manufactures", id);
      batch.set(manufactureRef, {
        versionNo: 0,
        ...manufacture,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export {
  ManufacturePageStatus as ManufacturePageQuery,
  ManufactureStoreState,
  ManufactureDoc,
  ManufactureDocData,
  ManufacturePageStatus,
};
