enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  AUTH_VERIFY_USER = "verifyAuth",
  AUTH_LOGIN = "login",
  AUTH_LOGOUT = "logout",
  AUTH_REGISTER = "register",
  UPDATE_USER = "updateUser",
  AUTH_FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  // account
  ACCOUNT_GET_SINGLE = "getAccountSingle",
  ACCOUNT_POPULATE_PAGE_QUERY = "getAccountPageQuery",
  ACCOUNT_POPULATE_TEXT_QUERY = "getAccountTextQuery",
  ACCOUNT_UPDATE_DOC = "updateAccountDoc",
  ACCOUNT_CREATE = "createAccountDoc",

  // subscription
  SUBSCRIPTION_POPULATE_PAGE_QUERY = "getSubscriptionPageQuery",
  SUBSCRIPTION_POPULATE_ACCOUNT = "getSubscriptionAccount",
  SUBSCRIPTION_UPDATE_DOC = "updateSubscriptionDoc",
  SUBSCRIPTION_CREATE = "createSubscriptionDoc",

  // rbw
  RBW_GET_SINGLE = "getRbwSingle",
  RBW_POPULATE_PAGE_QUERY = "getRbwPageQuery",
  RBW_POPULATE_TEXT_QUERY = "getRbwTextQuery",
  RBW_UPDATE_DOC = "updateRbwDoc",
  RBW_CREATE = "createRbwDoc",
  RBW_FETCH_OWNER_NAME = "fetchOwnerName",
  RBW_FETCH_OWNER_EMAIL = "fetchOwnerEMAIL",
  RBW_FETCH_OWNER_PHONE = "fetchOwnerPHONE",

  // harvest
  HARVEST_GET_SINGLE = "getHarvestSingle",
  HARVEST_POPULATE_PAGE_QUERY = "getHarvestPageQuery",
  HARVEST_POPULATE_TEXT_QUERY = "getHarvestTextQuery",
  HARVEST_UPDATE_DOC = "updateHarvestDoc",
  HARVEST_CREATE = "createHarvestDoc",

  // product
  PRODUCT_POPULATE_PAGE_QUERY = "getProductPageQuery",
  PRODUCT_POPULATE_TEXT_QUERY = "getProductTextQuery",
  PRODUCT_UPDATE_DOC = "updateProductDoc",
  PRODUCT_CREATE = "createProductDoc",

  // manufacture
  MANUFACTURE_POPULATE_PAGE_QUERY = "getManufacturePageQuery",
  MANUFACTURE_UPDATE_DOC = "updateManufactureDoc",
  MANUFACTURE_CREATE = "createManufactureDoc",

  // seminar
  SEMINAR_POPULATE_PAGE_QUERY = "getSeminarPageQuery",
  SEMINAR_POPULATE_TEXT_QUERY = "getSeminarTextQuery",
  SEMINAR_UPDATE_DOC = "updateSeminarDoc",
  SEMINAR_CREATE = "createSeminarDoc",

  // participant
  PARTICIPANT_POPULATE_PAGE_QUERY = "getParticipantPageQuery",
  PARTICIPANT_UPDATE_DOC = "updateParticipantDoc",
  PARTICIPANT_CREATE = "createParticipantDoc",

  // consultation
  CONSULTATION_POPULATE_PAGE_QUERY = "getConsultationPageQuery",
  CONSULTATION_POPULATE_TEXT_QUERY = "getConsultationTextQuery",
  CONSULTATION_UPDATE_DOC = "updateConsultationDoc",
  CONSULTATION_CREATE = "createConsultationDoc",

  // chat
  CHAT_START_CONSULTATION = "startChatConsultation",
  CHAT_POPULATE_PAGE_QUERY = "getChatPageQuery",
  CHAT_UPDATE_DOC = "updateChatDoc",
  CHAT_CREATE = "createChatDoc",

  // transaction
  TRANSACTION_POPULATE_PAGE_QUERY = "getTransactionPageQuery",
  TRANSACTION_POPULATE_TEXT_QUERY = "getTransactionTextQuery",
  TRANSACTION_UPDATE_DOC = "updateTransactionDoc",
  TRANSACTION_CREATE = "createTransactionDoc",

  // article
  ARTICLE_POPULATE_PAGE_QUERY = "getArticlePageQuery",
  ARTICLE_POPULATE_TEXT_QUERY = "getArticleTextQuery",
  ARTICLE_UPDATE_DOC = "updateArticleDoc",
  ARTICLE_DELETE_DOC = "deleteArticleDoc",
  ARTICLE_CREATE = "createArticleDoc",

  // video
  COURSE_POPULATE_PAGE_QUERY = "getVideoPageQuery",
  COURSE_POPULATE_TEXT_QUERY = "getVideoTextQuery",
  COURSE_UPDATE_DOC = "updateVideoDoc",
  COURSE_DELETE_DOC = "deleteVideoDoc",
  COURSE_CREATE = "createVideoDoc",

  // analytic
  ANALYTIC_INIT = "initAnalytic",

  // price
  PRICE_INIT = "initPrice",
  PRICE_UPDATE_DOC = "updatePrice",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  AUTH_PURGE_AUTH = "logOut",
  AUTH_SET_AUTH = "setAuth",
  AUTH_SET_USER = "setUser",
  AUTH_SET_PROFILE = "setProfile",
  AUTH_SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  // account
  ACCOUNT_SET_MODAL_USEREDIT = "setAccountModelUserEdit",
  ACCOUNT_SET_INDEX = "setAccountIndex",
  ACCOUNT_SET_INDEX_SUB = "setAccountIndexSub",
  ACCOUNT_SET_USER_DOC = "setAccountUserDoc",
  ACCOUNT_SET_PROFILE_DOC = "setAccountProfileDoc",
  ACCOUNT_SET_SUPERTEAM_DOC = "setAccountSperteamDoc",
  ACCOUNT_SET_PAGE_QUERY = "setAccountPagination",
  ACCOUNT_RESET_PAGINATION = "resetAccountPagination",
  ACCOUNT_SET_TEXT_QUERY = "setAccountQuery",

  // participant
  SUBSCRIPTION_SET_DOC = "setSubsccriptionDoc",
  SUBSCRIPTION_SET_ACCOUNT = "setSubsccriptionAccount",
  SUBSCRIPTION_SET_PAGE_QUERY = "setSubsccriptionPagination",
  SUBSCRIPTION_RESET_PAGINATION = "resetSubsccriptionPagination",

  // rbw
  RBW_SET_DOC = "setRbwDoc",
  RBW_SET_PAGE_QUERY = "setRbwPagination",
  RBW_RESET_PAGINATION = "resetRbwPagination",
  RBW_SET_TEXT_QUERY = "setRbwQuery",

  // harvest
  HARVEST_SET_MODAL_EDIT = "setHarvestModelEdit",
  HARVEST_SET_DOC = "setHarvestDoc",
  HARVEST_SET_PAGE_QUERY = "setHarvestPagination",
  HARVEST_RESET_PAGINATION = "resetHarvestPagination",
  HARVEST_SET_TEXT_QUERY = "setHarvestQuery",
  HARVEST_SET_SEARCH_TEXT = "setHarvestSearchText",
  HARVEST_SET_INDEX_SUB = "setHarvestIndexSub",
  HARVEST_SET_INDEX = "setHarvestIndex",

  // product
  PRODUCT_SET_DOC = "setProductDoc",
  PRODUCT_SET_PAGE_QUERY = "setProductPagination",
  PRODUCT_RESET_PAGINATION = "resetProductPagination",
  PRODUCT_SET_TEXT_QUERY = "setProductQuery",
  PRODUCT_SET_SEARCH_TEXT = "setProductSearchText",

  // manufacture
  MANUFACTURE_SET_DOC = "setManufactureDoc",
  MANUFACTURE_SET_PAGE_QUERY = "setManufacturePagination",
  MANUFACTURE_RESET_PAGINATION = "resetManufacturePagination",

  // seminar
  SEMINAR_SET_DOC = "setSeminarDoc",
  SEMINAR_SET_PAGE_QUERY = "setSeminarPagination",
  SEMINAR_RESET_PAGINATION = "resetSeminarPagination",
  SEMINAR_SET_TEXT_QUERY = "setSeminarQuery",

  // participant
  PARTICIPANT_SET_DOC = "setParticipantDoc",
  PARTICIPANT_SET_PAGE_QUERY = "setParticipantPagination",
  PARTICIPANT_RESET_PAGINATION = "resetParticipantPagination",

  // consultation
  CONSULTATION_SET_DOC = "setConsultationDoc",
  CONSULTATION_SET_PAGE_QUERY = "setConsultationPagination",
  CONSULTATION_RESET_PAGINATION = "resetConsultationPagination",
  CONSULTATION_SET_TEXT_QUERY = "setConsultationQuery",

  // chat
  CHAT_SET_DOC = "setChatDoc",
  CHAT_SET_CONSULTATION = "setChatConsultation",
  CHAT_SET_PAGE_QUERY = "setChatPagination",
  CHAT_RESET_PAGINATION = "resetChatPagination",

  // transaction
  TRANSACTION_SET_DOC = "setTransactionDoc",
  TRANSACTION_SET_PAGE_QUERY = "setTransactionPagination",
  TRANSACTION_RESET_PAGINATION = "resetTransactionPagination",
  TRANSACTION_SET_TEXT_QUERY = "setTransactionQuery",

  // article
  ARTICLE_SET_DOC = "setArticleDoc",
  ARTICLE_SET_PAGE_QUERY = "setArticlePagination",
  ARTICLE_RESET_PAGINATION = "resetArticlePagination",
  ARTICLE_SET_TEXT_QUERY = "setArticleQuery",

  // video
  COURSE_SET_DOC = "setVideoDoc",
  COURSE_SET_PAGE_QUERY = "setVideoPagination",
  COURSE_RESET_PAGINATION = "resetVideoPagination",
  COURSE_SET_TEXT_QUERY = "setVideoQuery",

  // analytic
  ANALYTIC_SET_INIT = "setAnalyticInit",
  ANALYTIC_SET_ACCOUNT = "setAnalyticAccount",
  ANALYTIC_SET_SALES = "setAnalyticSales",
  ANALYTIC_SET_PURCHASE = "setAnalyticPurchase",
  ANALYTIC_SET_HARVEST = "setAnalyticHarvest",

  // price
  PRICE_SET_INIT = "setPriceInit",
  PRICE_SET_DOC = "setPriceDoc",
}

export { Actions, Mutations };
